// https://github.com/samironbarai/ionic-sqlite-crud/blob/main/src/app/database.service.ts
import { Injectable } from "@angular/core";
import { SQLite, SQLiteObject } from "@ionic-native/sqlite/ngx";

@Injectable({
  providedIn: "root",
})
export class DatabaseService {

  databaseObj: SQLiteObject;
  tables = {
    geolocations: 'geolocations'
  }
  maxLocationRows = 30;

  constructor(
    private sqlite: SQLite,
  ) { }

  async createDatabase() {
    const config = {
      name: "coron",
      location: "default",
    }
    await this.sqlite.create(config).then((db: SQLiteObject) => {
      console.log('database created')
      this.databaseObj = db;
    })
      .catch((e) => {
        console.log("error on creating database " + JSON.stringify(e));
      });

    //await this.databaseObj.executeSql(`DROP TABLE IF EXISTS ${this.tables.geolocations}`);
    await this.createTables();
  }

  async createTables() {
    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.geolocations} (
        id INTEGER PRIMARY KEY AUTOINCREMENT, 
        tagId INTEGER NOT NULL,
        truckId INTEGER NOT NULL,
        truckingId INTEGER NULL,
        plateNo VARCHAR(20) NULL,
        siteId INTEGER NULL,
        data TEXT NOT NULL)`,
      []
    );
  }

  async addLocation(tagId: number, truckId: number, truckingId: number, plateNo: string, siteId: number, data: string) {
    console.log('addLocation tagId: ', tagId)
    const sqlStmt = `INSERT INTO ${this.tables.geolocations}(tagId, truckId, truckingId, plateNo, siteId, data) VALUES(${tagId}, ${truckId}, ${truckingId}, '${plateNo}', ${siteId}, '${data}')`;
    return this.databaseObj.executeSql(sqlStmt, []).then(() => {
      return "location created";
    })
      .catch((e) => {
        console.log('AddLocation Error: ', JSON.stringify(e));
        if (e.code === 6) {
          return "location already exists";
        }
        return "error on creating location " + JSON.stringify(e);
      });
  }

  async getLocations() {
    return this.databaseObj.executeSql(
      `SELECT * FROM ${this.tables.geolocations} ORDER BY id DESC LIMIT ${this.maxLocationRows}`,
      []
    )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return "error on getting locations" + JSON.stringify(e);
      });
  }

  async deleteLocations(ids: number[]) {
    let idsStr = '0';
    ids.forEach(id => {
      idsStr = idsStr + ', ' + id;
    });
    const sql = `DELETE FROM ${this.tables.geolocations} WHERE id IN (${idsStr})`;
    return this.databaseObj.executeSql(sql, [])
      .then(() => {
        return "locations deleted";
      })
      .catch((e) => {
        return "error on deleting location " + JSON.stringify(e);
      });
  }

  async getOldestLocation() {
    return this.databaseObj.executeSql(
      `SELECT * FROM ${this.tables.geolocations} ORDER BY id LIMIT 1`,
      []
    )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log('Error get oldest location', JSON.stringify(e));
        return "error on getting locations" + JSON.stringify(e);
      });
  }

}
