export class AuthConstants {
  public static readonly ADDRESS = 'address';
  public static readonly AUTH = 'userData';
  public static readonly LOADPLAN_LIST = 'loadplan-list';
  public static readonly LOADPLAN = 'loadplan';
  public static readonly LOADPLAN_PAST_JOBS = 'loadplan-past-jobs';
  public static readonly LOADPLAN_PAST_JOBS_FILTER = 'loadplan-past-jobs-filter';
  public static readonly LASTDROPSHIPMENT = 'lastDropShipment';
  public static readonly SELECTEDDATE = 'selected-date';
  public static readonly TRUCK = 'truckData';
  public static readonly TRUCK_LIST = 'truck-list';  
  public static readonly TRUCK_TYPES= 'truckTypes';
  public static readonly NOTIFICATION = 'notification';
  public static readonly NOTIFICATION_LIST = 'notification-list';  
  public static readonly ISSHOWNOTIFICATIONDETAILS = 'is-show-notification-detail';
  public static readonly API_KEY = 'Cwe0eNuguO5AtKUaIyQiQ9Ypa1vdsZh5apU1wpfQ';
  public static readonly TOKEN = 'token';
  public static readonly FIREBASE_TOKEN = 'firebase_token';
  public static readonly APP_NAME = 'coron';
  public static readonly CURRENT_ROUTE = 'current_route';
  public static readonly INTERNET_CONNECTION = 'internet_connection';
}
