import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertController: AlertController
  ) { }

  async presentAlert(header: string, subHeader: string, message: string) {
    const alert = await this.alertController.create({
      cssClass: 'alert-custom-class',
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentAlertConfirm(header: string, subHeader: string, message: string) {
    let choice;
    const alert = await this.alertController.create({
      cssClass: 'alert-custom-class',
      header: header,
      subHeader: subHeader,
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        }, {
          text: 'Proceed',
          role: 'okay',
        }
      ]
    });

    await alert.present();
    await alert.onDidDismiss().then(data => {
      choice = data;
    });
    return choice;

  }

  async presentAlertConfirm2(okayText : string,header: string, subHeader: string, message: string) {
    let choice;
    const alert = await this.alertController.create({
      cssClass: 'alert-custom-class',
      header: header,
      subHeader: subHeader,
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: okayText,
          role: 'okay',
        }
      ]
    });

    await alert.present();
    await alert.onDidDismiss().then(data => {
      choice = data;
    });
    return choice;

  }

}
