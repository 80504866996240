import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';
import { AuthConstants } from './../config/auth-constants';


@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  notificationsData$ = new BehaviorSubject<any>([]);
  chatData$ = new BehaviorSubject<any>([]);
  constructor(    
    private httpService: HttpService,
    private storageService: StorageService,
  ) { 
    
  }
  getNotifications(truckerId: Number, start: any, end : any): Observable<any> {
    return this.httpService.get(`truckernotifications?truckerId=${truckerId}&&start=${start}&&end=${end}`);
  }

  updateNotification(truckerId: Number, notification : any) : Observable<any> {
    return this.httpService.put(`TruckerNotifications/Read?truckerId=${truckerId}`,notification);
  }

  getNotificationsData() {
    this.storageService.get(AuthConstants.NOTIFICATION_LIST).then(res => {
      this.notificationsData$.next(res);
    });
  }
}
