import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./index/index.module').then( m => m.IndexPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  // {
  //   path: 'announcement',
  //   loadChildren: () => import('./pages/announcement/announcement.module').then( m => m.AnnouncementPageModule)
  // },
  // {
  //   path: 'jobs',
  //   loadChildren: () => import('./pages/jobs/jobs.module').then( m => m.JobsPageModule)
  // },
  // {
  //   path: 'confirm-trip',
  //   loadChildren: () => import('./pages/confirm-trip/confirm-trip.module').then( m => m.ConfirmTripPageModule)
  // },
  // {
  //   path: 'past-jobs',
  //   loadChildren: () => import('./pages/past-jobs/past-jobs.module').then( m => m.PastJobsPageModule)
  // },
  // {
  //   path: 'performance',
  //   loadChildren: () => import('./pages/performance/performance.module').then( m => m.PerformancePageModule)
  // },
  // {
  //   path: 'messages',
  //   loadChildren: () => import('./pages/messages/messages.module').then( m => m.MessagesPageModule)
  // },
  // {
  //   path: 'account-settings',
  //   loadChildren: () => import('./pages/account-settings/account-settings.module').then( m => m.AccountSettingsPageModule)
  // },
  // {
  //   path: 'notification-detail',
  //   loadChildren: () => import('./pages/notification-detail/notification-detail.module').then( m => m.NotificationDetailPageModule)
  // },
  // {
  //   path: 'privacy',
  //   loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  // },
  // {
  //   path: 'register-driver',
  //   loadChildren: () => import('./pages/register-driver/register-driver.module').then( m => m.RegisterDriverPageModule)
  // },
  // {
  //   path: 'web-view',
  //   loadChildren: () => import('./pages/web-view/web-view.module').then( m => m.WebViewPageModule)
  // },

  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
