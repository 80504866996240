import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { HttpService } from "./http.service";
import { StorageService } from "./storage.service";
import { AuthConstants } from "./../config/auth-constants";

@Injectable({
  providedIn: "root",
})
export class LoadplanService {
  loadPlanData$ = new BehaviorSubject<any>([]);

  _loadPlanList = new BehaviorSubject<any>([]);

  private _loadPlanList$ = this._loadPlanList.asObservable();

  public _loadPlan$ = new Subject<any>();
  private _loadPlanPastJobs = new BehaviorSubject<any>([]);
  private _loadPlanPastJobs$ = this._loadPlanPastJobs.asObservable();

  get loadPlanRefresh() {
    return this._loadPlan$;
  }

  constructor(
    private httpService: HttpService,
    private storageService: StorageService
  ) {}

  getLoadPlanLists() : Observable<any> {
    return this._loadPlanList$;
  }

  setLoadPlanList(latestValue: any) {
    return this._loadPlanList.next(latestValue);
  }

  _setLoadPlanPastJobs(latestValue: any) {
    return this._loadPlanPastJobs.next(latestValue);
  }

  _getLoadPlanPastJobs(): Observable<any> {
    return this._loadPlanPastJobs$;
  }

  updateLoadplan(id: Number, loadPlan: any): Observable<any> {
    return this.httpService.put(`loadplan/set-truck-driver?id=${id}`, loadPlan);
  }

  getLoadPlanData() {
    this.storageService.get(AuthConstants.LOADPLAN).then((res) => {
      this.loadPlanData$.next(res);
    });
  }

  getLoadplans(truckerId: Number): Observable<any> {
    return this.httpService.get(`loadplan?truckerId=${truckerId}`);
  }

  getPastJob(
    truckerId: Number,
    page: Number,
    truckingTypeId: Number,
    addressId: Number,
    startDate: Number,
    endDate: Number
  ): Observable<any> {
    return this.httpService.get(
      `loadplan/pastjobs?truckerId=${truckerId}&&page=${page}&&truckTypeId=${truckingTypeId}&&addressId=${addressId}&&startDate=${startDate}&&endDate=${endDate}`
    );
  }

  getLastDropShipment(loadPlanId): Observable<any> {
    return this.httpService.get(
      `loadplan/last-drop-shipment?loadPlanId=${loadPlanId}`
    );
  }

  getLoadPlanList() {
    this.storageService.get(AuthConstants.LOADPLAN_LIST).then((res) => {
      this.loadPlanData$.next(res);
    });
  }
}
