import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthConstants } from './../config/auth-constants';
import { HttpService } from './http.service';
import { 
  LoadplanService,
  StorageService,
} from './';
 

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData$ = new BehaviorSubject<any>([]); 

  constructor(
    private httpService: HttpService,
    private storageService: StorageService,
    private router: Router,
    private loadplanService: LoadplanService
  ) { }

  getUserData() {
    this.storageService.get(AuthConstants.AUTH).then(res => {
      this.userData$.next(res);
    });
  }

  login(postData: any): Observable<any> {
    return this.httpService.post('users/trucker/login', postData);
  }

  signup(postData: any): Observable<any> {
    return this.httpService.post('signup', postData);
  }

  async logout() {
    this.storageService.remove(AuthConstants.LOADPLAN_LIST);//clear load plan list when log out
    this.storageService.remove(AuthConstants.LOADPLAN_PAST_JOBS);//clear load plan past jobs list when log out
    this.storageService.remove(AuthConstants.AUTH);//clear auth token when log out
    this.storageService.remove(AuthConstants.NOTIFICATION_LIST);//cleare notification list after log out of user
    this.storageService.remove(AuthConstants.NOTIFICATION);//cleare viewed notfication
    this.storageService.remove(AuthConstants.LOADPLAN);//cleare viewed loadplan
    this.storageService.remove(AuthConstants.LASTDROPSHIPMENT);//cleared last drop shipment
    this.storageService.remove(AuthConstants.ISSHOWNOTIFICATIONDETAILS);//clear notification details
    
    // this.storageService.clear();//clear data of log user
    this.userData$.next('');
    this.httpService.setApiEnvironment(HttpService.ENV_PRODUCTION);
    this.router.navigate([''],{replaceUrl:true});//reset the whole app
  }

  getStoredUserData(): Promise<any> {
    return this.storageService.get(AuthConstants.AUTH);
  }

  getStoredLoadPlansData(): Promise<any> {
    return this.storageService.get(AuthConstants.LOADPLAN_LIST  );
  }

  closeTruckDriverTag(tagId: number): Observable<any> {
    return this.httpService.post(`truck-driver-taggings/close?tagId=${tagId}`, null);
  }

}

