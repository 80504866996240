import { Injectable } from '@angular/core';
import { pickerController } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class PickerService {

  constructor() { }

  async openPicker(columnOptions: string[], callBack ){
    const numColumns = 1;
    const numOptions = columnOptions.length
    const picker = await pickerController.create({
      columns: this.getColumns(numColumns, numOptions, [columnOptions]),
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Confirm',
          handler: (value) => {
            callBack(value.col0);
          }
        }
      ]
    });
    await picker.present();
  }

  getColumns(numColumns, numOptions, columnOptions) {
    let columns = [];
    for (let i = 0; i < numColumns; i++) {
      columns.push({
        name: `col${i}`,
        options: this.getColumnOptions(i, numOptions, columnOptions)
      });
    }

    return columns;
  }

  getColumnOptions(columnIndex, numOptions, columnOptions) {
    let options = [];
    for (let i = 0; i < numOptions; i++) {
      options.push({
        text: columnOptions[columnIndex][i % numOptions],
        value: i
      })
    }

    return options;
  }

}
