import { Capacitor } from '@capacitor/core';
import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import {
  NetworkService,
  DatabaseService,
  FcmService,
  //AppVersionService, 
} from './services';
import { Plugins } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private networkService: NetworkService,
    private databaseService: DatabaseService,
    //private appVersionService: AppVersionService
    private fcmService: FcmService,
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // Initialize Firebase Push Notification Service
      this.fcmService.initPush();

      if (Capacitor.getPlatform() === 'android') {
        this.databaseService.createDatabase();
      }
      
      // offline mode support
      this.networkService.addListener();

      // Check if there is new app version
      // this.appVersionService.checkNewVersion();

    });

    this.platform.resume.subscribe(async () => {
      //alert('Resume event detected');     
    });

  }

}

