import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { StorageService } from "./storage.service";
@Injectable({
  providedIn: "root",
})
export class AddressService {
  constructor(
    private httpService: HttpService,
    private storageService: StorageService
  ) {}

  getAllAddressFromDB() {
    return this.httpService.get(`ServiceableAreas`);
  }

}
