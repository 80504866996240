import { Injectable, Output } from '@angular/core';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { AlertService, LoadingService } from './'
import { WebIntent } from '@ionic-native/web-intent/ngx';
import { Plugins, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

const { Filesystem } = Plugins;

export const version = {
  "versionCode": 111,
  "versionName": "1.11", // Changes this every release of new build version
  "file": "output.json",
  "fileUrl": 'https://coron.s3-ap-southeast-1.amazonaws.com/download/apk/output.json',
  "apkUrl": 'https://coron.s3-ap-southeast-1.amazonaws.com/download/apk/coron-release_v1.apk',
  "apkFile": 'coron-release_v1.07.apk' // Changes this every release of new build version
}

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {

  constructor(
    private transfer: FileTransfer,
    private file: File,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private webIntent: WebIntent,
    private androidPermissions: AndroidPermissions
  ) {
    const fileTransfer: FileTransferObject = this.transfer.create();
  }

  async checkNewVersion() {
    this.checkPermission();
  }

  private async checkPermission() {
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then((result) => {
      if (result.hasPermission) {
        this.downloadVersionFile();
      } else {        
        this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE, this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE]).then(_ => {
          this.downloadVersionFile();
        }, (error) => {
          alert('Error: ' + JSON.stringify(error));
          console.log(error);
        });  
      }
    }, error => {
      console.log(error);
    });
  }

  private downloadVersionFile() {
    const fileTransfer: FileTransferObject = this.transfer.create();
    const url = version.fileUrl;
    fileTransfer.download(url, this.file.dataDirectory + version.file)
      .then((entry) => {
        console.log('download complete: ' + entry.toURL());
        this.readVersionFile();
      }, (error) => {
        console.log(error);
      });
  }

  private async readVersionFile() {
    let contents = await Filesystem.readFile({
      path: version.file,
      directory: FilesystemDirectory.Data,
      encoding: FilesystemEncoding.UTF8
    });
    const versionName = JSON.parse(contents.data)[0].apkData.versionName;
    if (versionName) {
      if ( parseFloat(versionName) > parseFloat(version.versionName)) {
        const ret = await this.alertService.presentAlertConfirm('Update App', null, 'A new version of FAST Trucker app is available, proceed to download and install?');
        if (ret.role == 'okay') {
          await this.downloadApk();
        }
      }
    }
  }

  private async downloadApk() {
    await this.loadingService.presentLoading('Downloading apk file.', null);
    const fileTransfer: FileTransferObject = this.transfer.create();
    const url = version.apkUrl;
    fileTransfer.download(url, this.file.externalDataDirectory + version.apkFile)
      .then((entry) => {
        this.loadingService.dismissLoading();
        this.installApk(entry);
      }, (error) => {
        this.loadingService.dismissLoading();
        console.log(error);
      });
  }

  private async installApk(entry) {
    await this.loadingService.presentLoading('Installing update.', null);
    const options = {
      action: this.webIntent.ACTION_VIEW,
      url: entry.nativeURL,  //entry.toURL(),
      type: 'application/vnd.android.package-archive'
    }
    this.webIntent.startActivity(options).then(res => {
      this.loadingService.dismissLoading();
    }, (error) => {
      this.loadingService.dismissLoading();
      alert('Error: ' + JSON.stringify(error))
      console.log("Failed to open URL via Android Intent. URL: " + entry.fullPath);
    });
  }

}

