import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';
import { AuthConstants } from './../config/auth-constants';

@Injectable({
  providedIn: 'root'
})
export class TruckService {

  trucksData$ = new BehaviorSubject<any>([]);

  constructor(
    private httpService: HttpService,
    private storageService: StorageService,
  ) { }

  getTrucks(truckerId: Number, location: string) : Observable<any> {
    return this.httpService.get(`trucks?truckerId=${truckerId}&&location=${location}`);
  }

  getTrucksData() {
    this.storageService.get(AuthConstants.TRUCK_LIST).then(res => {
      this.trucksData$.next(res);
    });
  }

  getTruckType() : Observable<any> {
    return this.httpService.get(`trucks/trucktypes`);
  }
  
}
