import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { AuthConstants } from '../config/auth-constants';
import { 
  LoadplanService, StorageService
} from './';

const { Network } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor(
    private loadPlanService: LoadplanService,
    private storageService: StorageService
  ) {}

  addListener() {
    let handler = Network.addListener('networkStatusChange', (status) => {
      console.log("Network status changed: Conneted=", JSON.stringify(status.connected));
      this.storageService.store(AuthConstants.INTERNET_CONNECTION, status.connected);
      if (status.connected === true) {
        // Wait a little before checking for update //
        setTimeout( () => {
         // this.loadPlanService.checkOfflineUpdate();
        }, 4000);      
        // wait to avoid overlap on accessing stored data //  
      }      
    });  
  }

  removeListeners() {
    Network.removeAllListeners();
  }

  async status(): Promise<any> {
    return await Network.getStatus();
  }

}
