import { Injectable, NgZone } from '@angular/core';
import {
  Plugins, CameraResultType, Capacitor, FilesystemDirectory,
  CameraPhoto, CameraSource
} from '@capacitor/core';
import { StorageService, DriverService } from './../services';

const { Camera, Filesystem, Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  public userPhoto: Photo;
  public photos: Photo[] = [];
  private PHOTO_STORAGE: string = "photos";

  constructor(
    private storageService: StorageService,
    private driverService: DriverService,
    private zone: NgZone
  ) { }

  public async addNewToGallery() {
    // Take a photo
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 50,
      //allowEditing: true,
    });
    // Save the picture and add it to photo collection
    const savedImageFile = await this.savePicture(capturedPhoto);
    this.photos.unshift(savedImageFile);
  }

  public async takeUserPhoto(): Promise<any> {
    // Take a photo
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 25,
      allowEditing: true,
    });
    // Save the picture and add it to photo collection
    const savedImageFile = await this.savePicture(capturedPhoto);
    return savedImageFile;
  }

  public async takePhoto(quality?: number): Promise<any> {
    // Take a photo
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      quality: quality ?? 100,
      allowEditing: false,
    });
    // Save the picture and add it to photo collection
    const savedImageFile = await this.savePicture(capturedPhoto);
    return savedImageFile;
  }

  private async savePicture(cameraPhoto: CameraPhoto) {
    // Convert photo to base64 format, required by Filesystem API to save
    const base64Data = await this.readAsBase64(cameraPhoto);
    // Write the file to the data directory
    const fileName = new Date().getTime() + '.jpeg';
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: FilesystemDirectory.Data
    });

    // Use webPath to display the new image instead of base64 since it's
    // already loaded into memory
    return {
      filepath: fileName,
      webviewPath: cameraPhoto.webPath,
      base64Data: base64Data,
      selected: false,
    };
  }

  private async readAsBase64(cameraPhoto: CameraPhoto) {
    // Fetch the photo, read as a blob, then convert to base64 format
    const response = await fetch(cameraPhoto.webPath);
    const blob = await response.blob();
    return await this.convertBlobToBase64(blob) as string;
  }

  /*
  async readAsBlob(webPath: string) {
    // Fetch the photo then return as a blob
    const response = await fetch(webPath);
    return await response.blob() as Blob;
  }
  */

  private convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = getFileReader();
    reader.readAsDataURL(blob);
    reader.onerror = () => {
      reject;
    }
    reader.onload = () => {
      resolve(reader.result);
    };

  });

  public savePhotos(photosKey: string) {
    this.storageService.store(photosKey, this.photos);
  }

  public async loadSaved(photosKey: string) {
    // Retrieve cached photo array data
    const photoList = await this.storageService.get(photosKey);
    this.photos = JSON.parse(photoList.value) || [];

    // Display the photo by reading into base64 format
    for (let photo of this.photos) {
      // Read each saved photo's data from the Filesystem
      const readFile = await Filesystem.readFile({
        path: photo.filepath,
        directory: FilesystemDirectory.Data
      });

      // Web platform only: Load the photo as base64 data
      photo.webviewPath = `data:image/jpeg;base64,${readFile.data}`;
    }
  }

  removeSelectedPhotos() {
    this.photos = this.photos.filter(p => p.selected === false);
  }

}

export interface Photo {
  filepath: string;
  webviewPath: string;
  base64Data: string;
  selected: boolean;
}

export function getFileReader(): FileReader {
  const fileReader = new FileReader();
  const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
  return zoneOriginalInstance || fileReader;
}


