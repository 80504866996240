import { Injectable, NgZone } from "@angular/core";
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Capacitor,
} from "@capacitor/core";
import { Router } from "@angular/router";
import {
  LoadingService,
  LoadplanService,
  StorageService,
  ToastService,
} from "./";
import { AuthConstants } from "./../config/auth-constants";
import { AlertService } from "./alert.service";
import { plainToClass } from "class-transformer";
import { LoadPlans, NewOffer } from "../models";

const { PushNotifications } = Plugins;

@Injectable({
  providedIn: "root",
})
export class FcmService {
  public static readonly NEW_DISPATCH = "new-dispatch";
  public static readonly NEW_REQUEST = "new-request";
  public static readonly NEW_TRUCKER_OFFER = "new-trucker-offer";

  constructor(
    private router: Router,
    private loadplanService: LoadplanService,
    private loadingService: LoadingService,
    private storageService: StorageService,
    private toastService: ToastService,
    private alertService: AlertService,
    private zone: NgZone
  ) {}

  initPush() {
    if (Capacitor.platform !== "web") {
      this.registerPush();
    }
  }

  private registerPush() {
    PushNotifications.requestPermission().then((permission) => {
      if (permission.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
        this.toastService.presentToast("No permission for push granted");
      }
    });

    PushNotifications.addListener(
      "registration",
      (token: PushNotificationToken) => {
        //alert('Push registration success, token: ' + token.value);
        this.storageService.store(AuthConstants.FIREBASE_TOKEN, token.value);
      }
    );

    PushNotifications.addListener("registrationError", (error: any) => {
      this.toastService.presentToast(
        "Push Notification Error: " + JSON.stringify(error)
      );
    });

    PushNotifications.addListener(
      "pushNotificationReceived",
      async (notification: PushNotification) => {
        console.log(notification);
        if (notification.data.Type) {
          if (notification.data.Type === FcmService.NEW_TRUCKER_OFFER) {
            //const newTruckerOffer: NewOffer = JSON.parse(notification.data.Data);
            //this.router.navigate(['home/jobs']);
            const res = await this.alertService.presentAlertConfirm2(
              "Okay",
              "New Offer!",
              "",
              notification.body
            );

            if (res.role == "okay") {
              this.storageService
                .get(AuthConstants.CURRENT_ROUTE)
                .then(async (route) => {
                  console.log(route);
                  if (route == "/home/jobs") {
                    // this.loadplanService._loadPlan$.next();
                    // const res = await this.alertService.presentAlertConfirm2(
                    //   "Okay",
                    //   "",
                    //   "Swipe down to see new offer.",
                    //   "",
                    // );
                    // location.reload();
                    
                    await this.loadingService.presentLoading(
                        "Loading jobs and offers",
                        null
                      );
                    this.storageService.get(AuthConstants.AUTH).then((u) => {
                      this.loadplanService
                        .getLoadplans(u.truckerInfo.Id)
                        .subscribe(
                          (result: any) => {
                            this.loadingService.dismissLoading();
                            if (result.data) {
                              
                              this.storageService
                                .store(AuthConstants.LOADPLAN_LIST, result.data)
                                .then(() => {
                                  this.loadplanService.setLoadPlanList(
                                    result.data as LoadPlans[]
                                  );
                                });
                            } else {
                              this.alertService.presentAlert(
                                "No data Found",
                                null,
                                "No data available."
                              );
                              console.log(result);
                            }
                          },
                          (error) => {
                            this.loadingService.dismissLoading();
                            if (error.status == 0) {
                              this.alertService.presentAlert(
                                "",
                                "NETWORK ISSUES",
                                "Check your internet connection."
                              );
                            } else if (error.error.status === "FAIL") {
                              // this.alertService.presentAlert('Jobs', null, "No Current Jobs");
                              this.toastService.presentToast(
                                "No Current Trips Available"
                              );
                            } else {
                              this.alertService.presentAlert(
                                "Error " + error.status,
                                error.statusText,
                                error.error.message +
                                  "<br/><br/>Please note or take screenshot to this error info and report this for support."
                              );
                              this.storageService.remove(
                                AuthConstants.LOADPLAN_LIST
                              ); //clear stored loadplan when no record where fetch from database
                            }
                            console.log(error);
                          }
                        );
                    });

                    // this.router.navigate([this.router.url]);
                  } else {
                    if (route != "/home/confirm-trip")
                      this.router.navigate(["/home/jobs"]);
                    // await this.loadingService.presentLoading(
                    //   "Loading jobs and offers",
                    //   null
                    // );
                    // this.storageService.get(AuthConstants.AUTH).then((res) => {
                    //   this.loadplanService
                    //     .getLoadplans(res.truckerInfo.Id)
                    //     .subscribe(
                    //       (res: any) => {
                    //         if (res.data) {
                    //           this.storageService
                    //             .store(AuthConstants.LOADPLAN_LIST, res.data)
                    //             .then(async () => {
                    //               console.log(res);
                    //               if (route == "/home/confirm-trip") {
                    //                 this.loadplanService.setLoadPlanList(
                    //                   res.data as LoadPlans[]
                    //                 );
                    //               } else {
                    //                 // this.router
                    //                 //   .navigateByUrl("/home/jobs", {
                    //                 //     skipLocationChange: true
                    //                 //   })
                    //                 //   .then(() => {
                    //                 //     this.router.navigate(["/home/jobs"]);
                    //                 //   });
                    //                 this.router.navigate(["/home/jobs"]);
                    //                 this.loadplanService.setLoadPlanList(
                    //                   res.data as LoadPlans[]
                    //                 );
                    //               }
                    //             });
                    //         }
                    //         this.loadingService.dismissLoading();
                    //       },
                    //       (error) => {
                    //         this.loadingService.dismissLoading();
                    //         //navigate
                    //         this.alertService.presentAlert(
                    //           error.status,
                    //           error.error.message,
                    //           null
                    //         );
                    //         // this.router.navigate(["home/jobs"]);
                    //       }
                    //     );
                    // });
                  }
                });
            }
          }
        }
      }
    );

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async (notification: PushNotificationActionPerformed) => {
        if (notification.notification.data.Type) {
        }
      }
    );
  }
}
