// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  title: 'Local Environment',
  apiURL: 'https://localhost:44367/',
  hereApiKey: 'YKRU-EaI3cY8XNP33bDriiNoxOxWQ9JeRmw2yvnXhQA',
  agGridLicenseKey: 'CompanyName=FASTCARGO LOGISTICS CORPORATION,LicensedGroup=FAST TMS,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-023113,ExpiryDate=31_December_2022_[v2]_MTY3MjQ0NDgwMDAwMA==ac40f6c533acf34a77841116978bb2ed',
  Auth: {
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_RPUCs7xhp",
    userPoolWebClientId: "48c2645tgf41n3ovqo8enm1fm1",
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  mapCenter: { lat: 14.424974987052124, lng: 121.03800221267117 },
  bookingAPIURL: 'https://kunzad2.fastlogistics.com.ph/fast_dev/',
  // googleCloudVisionAPIKey: '32d88cc6efe69783179d89520396bffc1f8e9604'
  googleApiKey: 'AIzaSyAd8Kwi-MzcIqOvLTHLhfoIIPqKJR3zbss',
  webViewUrl: 'http://localhost:3000/'
  //webViewUrl: 'https://appindex-trucker.fastlogistics.ph/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.