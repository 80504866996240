import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthConstants } from './../config/auth-constants';
import { StorageService } from './../services';

const ENV_STORAGE_KEY = 'api-environment-key';
const PRODUCTION_API_URL = 'https://api.limasawa.fast.com.ph/api/';
const DEVELOPMENT_API_URL = 'https://kunzad2.fastlogistics.com.ph/fast_dev/api/';
export const LOCATION_API_URL = "https://api.siquijor.fast.com.ph/api/";
// const PRODUCTION_API_URL = 'https://kunzad2.fastlogistics.com.ph/fast_dev/api/'
// const DEVELOPMENT_API_URL = 'http://localhost:53974/api/'

@Injectable({
  providedIn: 'root'
})

export class HttpService {

  public static ENV_PRODUCTION = 'prod';
  public static ENV_DEVELOPMENT = 'dev';

  apiUrl: string;

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Login-Token': 'TEST_LOGIN_TOKEN',
    'x-api-key': AuthConstants.API_KEY
  });
  httpOptions = { headers: this.headers }

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) {

    this.getApiEnvironment().then(env => {
      if (env && env == HttpService.ENV_DEVELOPMENT) {
        this.apiUrl = DEVELOPMENT_API_URL;
        this.setApiEnvironment(HttpService.ENV_DEVELOPMENT);
      } else {
        this.apiUrl = PRODUCTION_API_URL;
        this.setApiEnvironment(HttpService.ENV_PRODUCTION);
      }
    })

    this.storageService.get(AuthConstants.AUTH).then((userData) => {
      if (userData) {
        this.addTokenToHeader(userData.token);
      }
    });
  }

  post(serviceName: string, data: any) {
    let url = this.apiUrl + serviceName;
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }

  put(serviceName: string, data: any) {
    let url = this.apiUrl + serviceName;
    return this.http.put(url, JSON.stringify(data), this.httpOptions);
  }

  get(serviceName: string) {
    let url = this.apiUrl + serviceName;
    return this.http.get(url, this.httpOptions);
  }

  addTokenToHeader(token: string) {
    this.headers = this.headers.set('Token', token);
    this.httpOptions = { headers: this.headers }
  }

  async setApiEnvironment(env) {
    await this.storageService.store(ENV_STORAGE_KEY, env)
    if (env == HttpService.ENV_DEVELOPMENT) {
      this.apiUrl = DEVELOPMENT_API_URL;
    }
    if (env == HttpService.ENV_PRODUCTION) {
      this.apiUrl = PRODUCTION_API_URL;
    }
  }

  async getApiEnvironment() {
    return await this.storageService.get(ENV_STORAGE_KEY);
  }

  post2(serviceName: string, data: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Token': 'Cwe0eNuguO5AtKUaIyQiQ9Ypa1vdsZh5apU1wpfQ',
    });
    let httpOptions = { headers: headers }
    let url = LOCATION_API_URL + serviceName;
    return this.http.post(url, JSON.stringify(data), httpOptions);
  }

}

