import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loading: any;

  constructor(public loadingController: LoadingController) {}

  async presentLoading(message: string, duration: number) {
    this.loading = await this.loadingController.create({
      cssClass: 'loading-custom-class',
      message: message,
      duration: duration,
    });
    await this.loading.present();
  }

  dismissLoading() {
    //this.loading.dismiss();
    this.loadingController.dismiss();
  }

}
