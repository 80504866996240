import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService, StorageService } from './../services';

@Injectable({
  providedIn: 'root'
})
export class DriverService {

  list$ = new BehaviorSubject<any[]>([]);

  constructor(
    private httpService: HttpService,
    private storageService: StorageService
  ) { }

  postTruckDriverTagging(postData: any): Observable<any> {
    return this.httpService.post('truck-driver-taggings', postData);
  }

  postTruckDriverLocation(postData: any): Observable<any> {
    return this.httpService.post('truck-driver-location', postData);
  }

  postTruckDriverLocations(postData: any): Observable<any> {
    return this.httpService.post('truck-driver-location/location', postData);
  }

  postDriverPhoto(postData: any): Observable<any> {
    return this.httpService.post('drivers/photo', postData);
  }

  postLocations(postData: any): Observable<any> {
    return this.httpService.post2('truck-locations', postData);
  }

  getDrivers(truckerId: Number): Observable<any> {
    return this.httpService.get(`drivers/truckerapp?truckerId=${truckerId}`);
  }

  postRegisterDriver(postData: any): Observable<any> {
    return this.httpService.post('drivers/register', postData);
  }

}
